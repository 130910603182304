<template>
    <div class="app">
        <Header/>
        <!-- Content -->
        <div class="container">
            <div class="row mt-5">
                <div class="col mt-5">
                    <h1 style="color: #FE9800; font-weight: bold;">Our Service</h1>
                    <hr style="border: 2px solid #0077E5;margin-left: -120px;width: 330px;">
                </div>
            </div>
            <!-- <div class="row mt-2 mt-md-2 mt-lg-0">
                <div class="col">
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                        nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                        Ut wisi enim ad minim Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim. Lorem ipsum dolor sit amet, consectetuer
                        adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad minim</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr style="border: 1px solid #0077E5;">
                </div>
            </div> -->

            <div class="row mt-3 mt-md-3 mt-lg-3">
                <div class="col">
                    <h3 style="color: #FE9800; font-weight: bold;">Digital Service</h3>
                </div>
            </div>
            <div class="row mt-2 mt-md-2 mt-lg-0">
                <div class="col">
                    <p>We are here to boost up your business’ growth. We’ve evolved into a leading digital marketing agency with a data-driven approach, helping clients both small and large reap the benefits of advanced digital marketing. -</p>
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <div class="col-11">
                    <div class="row mt-3 mb-5">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row">
                                <img src="../assets/images/our-services/website.png" class="mt-1" alt="" width="60px" height="50px">
                                <div class="col">
                                    <div class="title-child">Website Design</div>
                                    <div class="content-child">Websites are the foundation of marketing excellence. Design for user experience (UX), and conversion. Manage with a robust CMS.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 ">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/our-services/seo.png" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">SEO</div>
                                    <div class="content-child">Improve organic search traffic to your website with on-page and off-page search engine optimization.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/our-services/paid-search.png" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Paid Search</div>
                                    <div class="content-child">Drive qualified traffic to your website and increase conversions. Google AdWords and Bing Ads certified.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/our-services/sosmed.png" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Social Media</div>
                                    <div class="content-child">Distribute content, engage prospects, generate leads and sales via social media. Optimize each network.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/our-services/content-marketing.png" alt="" width="60px" height="50px">
                                <div class="col">
                                    <div class="title-child">Content Marketing</div>
                                    <div class="content-child">Blogging, inbound marketing, thought leadership, and gated strategic content for lead generation.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/our-services/reporting.png" alt="" width="50px" height="60px">
                                <div class="col">
                                    <div class="title-child">Analytics and Reporting</div>
                                    <div class="content-child">Make data-driven decisions with advanced analytics and reporting. Google Analytics certified.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/other-areas/software.png" class="mt-1" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">ERP & CRM</div>
                                    <div class="content-child">Deep expertise in business and enterprise software marketing. Complete marketing services including branding, all aspects of digital, channel marketing, and more.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <hr style="border: 1px solid #0077E5;">
                </div>
            </div>

            <div class="row mt-3 mt-md-3 mt-lg-3">
                <div class="col">
                    <h3 style="color: #FE9800; font-weight: bold;">Traditional Service</h3>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p>MMP started as a traditional ad agency in 1996, so unlike new age digital agencies, we are grounded in disciplines still critical for complete marketing excellence. Importantly, we understand how to integrate traditional and digital for
                        maximum effectiveness. </p>
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <div class="col-11">
                    <div class="row mt-3 mb-5">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/advertising.png" class="mt-1" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Advertising</div>
                                    <div class="content-child">Complete creative and production for print, TV, radio, online, outdoor. Including media planning, buying/negotiation, talent, photography or video.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 ">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/brand-identity.png" alt="" width="60px" height="70px">
                                <div class="col">
                                    <div class="title-child">Brand Identity </div>
                                    <div class="content-child">Brand name development, logo creation, complete graphic themes and identities, identity guidelines and standards, and related. </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/direct-mail.png" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Direct Mail </div>
                                    <div class="content-child">Postcards and dimensional mailers reach decision makers. We manage creative, lists, and mailing.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/brochure-design.png" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Brochure Design</div>
                                    <div class="content-child">Complete brochure and catalog design, production, copy, photography, digital retouching, and related services. Both print and digital forms.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/event-marketing.png" class="mt-1" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Event Marketing</div>
                                    <div class="content-child">Trade shows and live events. Webcasts and virtual events. Event promotion and coordination. Exhibits and graphics.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/packaging.png" alt="" width="60px" height="60px">
                                <div class="col">
                                    <div class="title-child">Packaging</div>
                                    <div class="content-child">Make a good first impression with packaging that sells your product.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="row mt-3 mt-md-3 mt-lg-0">
                                <img src="../assets/images/traditional-services/product-launch.png" class="mt-1" alt="" width="60px" height="30px">
                                <div class="col col-lg-8">
                                    <div class="title-child">Product Launch</div>
                                    <div class="content-child">Successful product launch requires marketing excellence. From branding to all aspects of digital. Sales and dealer communications, and related.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

        </div>
        <!-- footer -->
        <footer-page/>
    </div>
</template>

<script>
import FooterPage from '../components/FooterPage.vue'
import Header from '../components/Header.vue'
export default {
    name: 'OurService',
    components: {
        FooterPage,
        Header
    },
    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>